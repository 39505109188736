import { createAsync, useCurrentMatches } from '@solidjs/router';
import { Show, Suspense } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { useTrackEvent } from '@troon/analytics';
import { createContentfulRequest } from '../routes/api/content/[model]/_client';
import { RichText } from './rich-text';

export function SiteBanner() {
	const matches = useCurrentMatches();
	const track = useTrackEvent();

	const banner = createAsync(
		async () => {
			const hideBanner = matches().some((match) => !!match.route.info?.hideBanner);
			if (hideBanner) {
				return undefined;
			}
			const slug =
				matches().find((match) => match.route.info?.siteBanner)?.route.info?.siteBanner || 'troon-access-upsell';
			const banner = await getBanner(slug);
			return { slug, banner };
		},
		{ deferStream: true },
	);

	return (
		<Suspense>
			<Show when={banner()?.banner && banner()}>
				{(data) => (
					<div
						onClick={() => {
							track('siteBannerClicked', { siteBAnner: data().slug });
						}}
						class={twJoin(
							'relative z-40 flex flex-row flex-wrap items-center justify-center gap-1 px-4 py-2 text-center text-sm text-white [&_a]:text-nowrap [&_a]:underline [&_a]:before:absolute [&_a]:before:inset-0',
							data().banner!.fields.backgroundStyle === 'Access' &&
								'bg-gradient-to-r from-neutral-950 to-brand-700 [&_a]:text-white',
							data().banner!.fields.backgroundStyle === 'Neutral' && 'bg-neutral-100',
							data().banner!.fields.backgroundStyle === 'Brand' && 'bg-brand-100 text-brand-600',
						)}
					>
						<RichText document={data().banner!.fields.content} />
					</div>
				)}
			</Show>
		</Suspense>
	);
}

const getBanner = createContentfulRequest('site-banner');
