import posthog from 'posthog-js';

type Config = {
	apiHost?: string;
	key: string;
	userId?: string;
	featureFlags?: Record<string, string | boolean>;
};

export function createClient(config: Config) {
	const ph = posthog.init(config.key, {
		api_host: config.apiHost ?? 'https://app.posthog.com',
		ui_host: 'https://us.posthog.com',
		bootstrap: {
			distinctID: config.userId,
			isIdentifiedID: !!config.userId,
			featureFlags: config.featureFlags,
		},
		persistence: 'localStorage',
		person_profiles: 'identified_only',
		disable_surveys: true,
		disable_session_recording: false,
	})!;
	return ph;
}
