import { splitProps } from 'solid-js';
import { ErrorBoundary } from '../components/error-boundary';
import { BaseLayout } from './base';
import type { ComponentProps } from 'solid-js';

type Props = ComponentProps<typeof BaseLayout>;

export function GenericLayout(props: Props) {
	const [local, rest] = splitProps(props, ['children']);
	return (
		<BaseLayout {...rest}>
			<ErrorBoundary contained>{local.children}</ErrorBoundary>
		</BaseLayout>
	);
}
