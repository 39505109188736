import { shouldPolyfill as shouldPolyfillNumber } from '@formatjs/intl-numberformat/should-polyfill';
import { setTag } from '@sentry/solidstart';

export async function polyfill() {
	// https://gist.github.com/paullewis/55efe5d6f05434a96c36
	window.requestIdleCallback =
		window.requestIdleCallback ||
		function (cb) {
			return setTimeout(function () {
				const start = Date.now();
				cb({
					didTimeout: false,
					timeRemaining: function () {
						return Math.max(0, 50 - (Date.now() - start));
					},
				});
			}, 1);
		};

	window.cancelIdleCallback =
		window.cancelIdleCallback ||
		function (id) {
			clearTimeout(id);
		};

	const dataPolyfills: Array<Promise<unknown>> = [];

	const fillNumber = shouldPolyfillNumber();
	setTag('polyfill.number', fillNumber);
	if (fillNumber) {
		await import('@formatjs/intl-numberformat/polyfill-force');

		dataPolyfills.push(import('@formatjs/intl-numberformat/locale-data/en'));
	}

	const fills = await Promise.all(dataPolyfills);
	return fills;
}
