import { useLocation } from '@solidjs/router';
import { createEffect } from 'solid-js';
import { useAnalytics } from './context';

export function useTrackPageview() {
	const location = useLocation();
	const posthog = useAnalytics();

	createEffect(() => {
		if (location.pathname) {
			posthog?.capture('$pageview');
		}
	});
}
