import { useIsEnabled } from '@troon/analytics';
import { isServer } from 'solid-js/web';
import { captureException } from '@sentry/solidstart';
import type { ParentProps } from 'solid-js';

const swFile = import.meta.env.MODE === 'production' ? '/sw.js' : undefined; //'/dev-sw.js?dev-sw';

export function ServiceWorker(props: ParentProps) {
	if (!isServer) {
		if ('serviceWorker' in navigator) {
			const enabled = useIsEnabled('service-worker-enabled', true) && swFile;
			if (enabled) {
				navigator.serviceWorker.register(swFile, { type: import.meta.env.DEV ? 'module' : 'classic' }).catch((e) => {
					captureException(new Error('Failed to register service worker', { cause: e }), { level: 'warning' });
				});
			} else {
				navigator.serviceWorker.getRegistrations().then((registrations) => {
					for (const reg of registrations) {
						reg.unregister();
					}
				});
			}
		}
	}

	return props.children;
}
