import { addBreadcrumb, breadcrumbsIntegration, captureException, init } from '@sentry/solidstart';
import { getAccessibleName } from 'accname';
import { isServer } from 'solid-js/web';
import { getConfigValue } from '../config';
import { ignoreErrors } from './shared';
import type { PostHog } from 'posthog-js';

export function initSentry(posthogClient?: PostHog) {
	const dsn = getConfigValue('SENTRY_DSN');
	if (!dsn) {
		return;
	}

	const sampleRate =
		getConfigValue('ENVIRONMENT') === 'production'
			? parseFloat(window.__FLAGS__ ? ((window.__FLAGS__['web-profiling'] as string) ?? '0.1') : '0.1')
			: 0;

	const integrations: Parameters<typeof init>[0]['integrations'] = [
		breadcrumbsIntegration({
			history: true,
			sentry: true,
		}),
	];

	if (posthogClient) {
		integrations.push(
			posthogClient.sentryIntegration({
				organization: 'troon-wl',
				projectId: parseInt(getConfigValue('SENTRY_DSN').split('/').at(-1)!, 10),
				severityAllowList: ['error', 'fatal'],
			}),
		);
	}

	init({
		dsn,
		environment: getConfigValue('ENVIRONMENT', 'development'),
		integrations,
		tracePropagationTargets: [/^https:\/\/(www|staging|localhost)\.?troon\.com/],
		tracesSampleRate: sampleRate,
		profilesSampleRate: sampleRate,
		release: (isServer ? import.meta.env.VITE_GIT_SHA : window.__CTX__?.sha) ?? '',
		ignoreErrors,
		beforeBreadcrumb(breadcrumb, hint) {
			if (hint?.event.currentTarget) {
				const name = getAccessibleName(hint.event.target);
				if (name) {
					breadcrumb.message = `"${name}" (${breadcrumb.message})`;
				}
			}
			return breadcrumb;
		},
	});

	window.addEventListener('vite:preloadError', (e) => {
		addBreadcrumb({ message: 'Caught dynamic import preload error, will reload window…' });
		captureException(e.payload);
		window.location.reload();
	});
}
